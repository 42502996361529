import { PageProps } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Sostienici from '../containers/sostienici';

const SostieniciPage: React.FunctionComponent<PageProps> = () => {
  return (
    <Layout>
      <SEO
        title="Sostienici"
        description="Sostienici nelle nostre attivita."
      />

      <Sostienici />
    </Layout>
  );
};

export default SostieniciPage;
