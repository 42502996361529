import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import GatsbyImage from '../../components/gatsby-image';
import SocialProfile from '../../components/social-profile/social-profile';
import {
  IoLogoFacebook,
  IoLogoInstagram,
} from 'react-icons/io';
import {
  SostieniciWrapper,
  SostieniciImage,
  SostieniciPageTitle,
  SostieniciDetails,
  SocialProfiles,
} from './style';

const SocialLinks = [
  {
    icon: <IoLogoFacebook />,
    url: 'https://www.facebook.com/people/Gruppo-Scout-FSE-Este-1/100090029058957/',
    tooltip: 'Facebook',
  },
  {
    icon: <IoLogoInstagram />,
    url: 'https://www.instagram.com/gruppoeste1.fse/',
    tooltip: 'Instagram',
  },
];

interface SostieniciProps { }

const Sostienici: React.FunctionComponent<SostieniciProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/locandina_low.jpeg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `);

  return (
    <SostieniciWrapper>
      <SostieniciPageTitle>
        <h2>Sostienici</h2>
      </SostieniciPageTitle>

      <SostieniciDetails>
        <p>
          Cara Sorella e Fratello Scout, ti invitiamo a <b>partecipare</b> a tutti gli appuntamenti del Centenario che sono indicati nel sito <a href='https://centenario.scouteste.it/programma'>centenario.scouteste.it/programma</a>, o che puoi ricevere via email o whatsapp.
        </p>

        <p>
          Per sostenere le attività programmate ti chiediamo anche di poter contribuire con un versamento al seguente IBAN <b>IT64K0872862560000000054253</b> (GRUPPO SCOUT FSE ESTE 1 'DON GIOVANNI FAGGIN', Banca Patavina) dedicato alla realizzazione delle attività del centenario.
        </p>

        <p>
          Alla fine dell'anno i fondi rimasti (<i>Lo Scout è laborioso ed economo</i>) saranno devoluti al Gruppo per sostenere le attività dei ragazzi.
        </p>

        <p>
          In attesa di incontrarci nei prossimi appuntamenti ti auguriamo <b>Buona Strada</b>.
        </p>
        <SocialProfiles>
          <SocialProfile items={SocialLinks} />
        </SocialProfiles>
      </SostieniciDetails>
    </SostieniciWrapper>
  );
};

export default Sostienici;
